const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  REGISTER_BEGIN: 'REGISTER_BEGIN',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_ERR: 'REGISTER_ERR',

  CONFIRM_BEGIN: 'CONFIRM_BEGIN',
  CONFIRM_SUCCESS: 'CONFIRM_SUCCESS',
  CONFIRM_ERR: 'CONFIRM_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  FORGOT_PASSWORD_BEGIN: 'FORGOT_PASSWORD_BEGIN',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERR: 'FORGOT_PASSWORD_ERR',

  CHANGE_PASSWORD_BEGIN: 'CHANGE_PASSWORD_BEGIN',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERR: 'CHANGE_PASSWORD_ERR',

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: (data) => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: (err) => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  registerBegin: () => {
    return {
      type: actions.REGISTER_BEGIN,
    };
  },

  registerSuccess: (data) => {
    return {
      type: actions.REGISTER_SUCCESS,
      data,
    };
  },

  registerErr: (err) => {
    return {
      type: actions.REGISTER_ERR,
      err,
    };
  },

  confirmBegin: () => {
    return {
      type: actions.CONFIRM_BEGIN,
    };
  },

  confirmSuccess: (data) => {
    return {
      type: actions.CONFIRM_SUCCESS,
      data,
    };
  },

  confirmErr: (err) => {
    return {
      type: actions.CONFIRM_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: (data) => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: (err) => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },

  forgotPasswordBegin: () => {
    return {
      type: actions.FORGOT_PASSWORD_BEGIN,
    };
  },

  forgotPasswordSuccess: () => {
    return {
      type: actions.FORGOT_PASSWORD_SUCCESS,
    };
  },

  forgotPasswordErr: (err) => {
    return {
      type: actions.FORGOT_PASSWORD_ERR,
      err,
    };
  },

  changePasswordBegin: () => {
    return {
      type: actions.CHANGE_PASSWORD_BEGIN,
    };
  },

  changePasswordSuccess: (data) => {
    return {
      type: actions.CHANGE_PASSWORD_SUCCESS,
      data,
    };
  },

  changePasswordErr: (err) => {
    return {
      type: actions.CHANGE_PASSWORD_ERR,
      err,
    };
  },

};

export default actions;
