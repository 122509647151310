// hudtat-midmuh-1weSbu
import { Amplify } from 'aws-amplify';
import { ConfigProvider, Spin } from 'antd';
import 'antd/dist/antd.less';
import React, { useEffect, useState, lazy } from 'react';
import { Provider, useSelector } from 'react-redux';
import { isLoaded, ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import store, { rrfProps } from './redux/store';

import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';

Amplify.configure({
  Auth: {
    identityPoolId: 'us-east-1:1f0fe47e-e788-4a2c-a47b-f37cf14e6720',
    region: 'us-east-1',
    userPoolId: 'us-east-1_wRXK3ljXr',
    userPoolWebClientId: '1v3oas8lmtiunudeb0iuahg2mv',
  },
  // Add in our new API, "name" can be whatever we want
  // no final slash "/" at the end of the endpoint
  API: {
    endpoints: [
      {
        name: 'raas',
        endpoint: 'https://bnduxxapw3.execute-api.us-east-1.amazonaws.com/V1',
      },
    ],
  },
});

const NotFound = lazy(() => import('./container/pages/404'));

const { themeColor } = config;

function ProviderConfig() {
  const { rtl, isLoggedIn, topMenu, mainContent, auth } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
      auth: state.auth,
    };
  });

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          {!isLoaded(auth) ? (
            <div className="spin">
              <Spin />
            </div>
          ) : (
            <>
              <Router basename={process.env.PUBLIC_URL}>
                {!isLoggedIn ? (
                  <Routes>
                    <Route path="/*" element={<Auth />} />{' '}
                  </Routes>
                ) : (
                  <Routes>
                    <Route path="/admin/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                )}
                {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
                  <Routes>
                    <Route path="/" element={<Navigate to="/admin" />} />
                  </Routes>
                )}
              </Router>
            </>
          )}
        </ReactReduxFirebaseProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
