import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Dashboard = lazy(() => import('../../container/dashboard'));
const DataQuality = lazy(() => import('../../container/dashboard/DataQuality'));
const GLReconcilement = lazy(() => import('../../container/dashboard/GLReconcilement'));
const NotFound = lazy(() => import('../../container/pages/404'));

function DashboardRoutes() {
  return (
    <Routes>
      <Route index element={<Dashboard />} />
      <Route path="data-quality" element={<DataQuality />} />
      <Route path="gl-reconcilement" element={<GLReconcilement />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default DashboardRoutes;
