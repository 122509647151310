import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import { readNotificationReducer } from './notification/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import themeUsersReducer from './themeUsers/reducers';

const rootReducers = combineReducers({
  themeUsers: themeUsersReducer,
  notification: readNotificationReducer,
  auth: authReducer,
  ChangeLayoutMode,
});

export default rootReducers;
