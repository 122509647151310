import { startTransition } from 'react';
import Cookies from 'js-cookie';
import { Auth } from 'aws-amplify';
import actions from './actions';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  confirmBegin,
  confirmSuccess,
  confirmErr,
  registerBegin,
  registerSuccess,
  registerErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  forgotPasswordBegin,
  forgotPasswordSuccess,
  forgotPasswordErr,
  changePasswordBegin,
  changePasswordSuccess,
  changePasswordErr,
} = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const { username, password } = values;
      const response = await Auth.signIn(username, password);
      // console.log('Cognito response', response);
      Cookies.set('access_token', response.signInUserSession.accessToken);
      Cookies.set('loggedIn', true);
      Cookies.set('username', response.username);
      dispatch(loginSuccess(true));
      callback();
    } catch (err) {
      console.log('Cognito error');
      dispatch(loginErr(err));
    }
  };
};

const confirm = (values, callback) => {
  // console.log('received request to confirm,',values);
  return async (dispatch) => {
    dispatch(confirmBegin());
    try {
      const { username, code } = values;
      // const { user } =
      await Auth.confirmSignUp(username,code);
      // console.log('did i get a user after confirm signup?? ',user);
      dispatch(confirmSuccess(true));
      callback();
    } catch (error) {
      dispatch(confirmErr('Confirmation code failed!'));
    }
  };
};

const register = (values, callback) => {
  return async (dispatch) => {
    dispatch(registerBegin());
    try {
      const { username, password, email } = values;
      await Auth.signUp({
        username,
        password,
        attributes: {
          email,
        },
      });
      dispatch(registerSuccess(true));
      callback();
    } catch (error) {
      console.log('error register:', error);
      dispatch(registerErr(`Registration failed: ${error}`));
    }
  };
};

const logOut = (callback) => {
  // console.log('received request to logOut callback',callback);

  return async (dispatch) => {
    startTransition(() => {
      dispatch(logoutBegin());
      try {
        Cookies.remove('logedIn');
        Cookies.remove('access_token');
        Cookies.remove('username');
        dispatch(logoutSuccess(false)); // sets the 'login' of 'auth' to this value: false
        callback();
      } catch (err) {
        dispatch(logoutErr(err));
      }
    });

  };
};

const forgotPassword = (values, callback) => {
  // console.log('forgot password values, callback',values, callback)
  return async (dispatch) => {
    dispatch(forgotPasswordBegin());
    try {
      const { username } = values;
      // console.log('username', username)
      if ( username === undefined ) {
        dispatch(forgotPasswordErr('Username cannot be empty'));
      }
      else {
        // Send confirmation code to user's email
        Auth.forgotPassword(username);
        dispatch(forgotPasswordSuccess());
        callback();
      }
    } catch (err) {
      // console.log('forgot password err ',err)
      dispatch(forgotPasswordErr(err));
    }
  };
};


const changePassword = (values, callback) => {
  console.log('changePassword values',values);

  return async (dispatch) => {
    dispatch(changePasswordBegin());
    try {
      const { username, code, newPassword } = values;
      // console.log('username', username)
      if ( username === undefined ) {
        dispatch(changePasswordErr('Username cannot be empty'));
      }
      else if ( code === undefined ) {
        dispatch(changePasswordErr('Code cannot be empty'));
      }
      else if ( newPassword === undefined ) {
        dispatch(changePasswordErr('Password cannot be empty'));
      }
      else {
        // Send confirmation code to user's email
        await Auth.forgotPasswordSubmit(username, code, newPassword);
        dispatch(changePasswordSuccess());
        callback();
      }
    } catch (err) {
      console.log('changePasswordErr err ',err)
      dispatch(changePasswordErr(err));
    }
  };
};

export { login, logOut, register, confirm, forgotPassword, changePassword };
