import Cookies from 'js-cookie';
import actions from './actions';

const {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  REGISTER_BEGIN,
  REGISTER_SUCCESS,
  REGISTER_ERR,
  CONFIRM_BEGIN,
  CONFIRM_SUCCESS,
  CONFIRM_ERR,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR,
  FORGOT_PASSWORD_BEGIN,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERR,
  CHANGE_PASSWORD_BEGIN,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERR,
} = actions;

const initState = {
  error: null,
  login: Cookies.get('logedIn'),
  loading: false,
  username: Cookies.get('username'),
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CONFIRM_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CONFIRM_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case CONFIRM_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case REGISTER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case REGISTER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
        username: '',
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case FORGOT_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        login: false,
        loading: false,
      };
    case FORGOT_PASSWORD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CHANGE_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
        username: '',
      };
    case CHANGE_PASSWORD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default AuthReducer;
